import { useTranslations } from 'next-intl'

import Card from './card'
import type { JobOfferType } from './jobOffer'
import JobOffer from './jobOffer'
import { useRouter } from 'next/router'

const internshipProjectNumberLanguageMap = {
  '1328689': 'de',
  '1457413': 'en',
  '1457387': 'it',
  '1457382': 'fr',
} as const

const speculativesProjectNumberLanguageMap = {
  '1326638': 'de',
  '1457372': 'en',
  '1457368': 'it',
  '1457357': 'fr',
} as const

const reentryProjectNumberLanguageMap = {
  '1443426': 'de',
} as const

const staticOfferProjectNames = [
  ...Object.keys(reentryProjectNumberLanguageMap),
  ...Object.keys(speculativesProjectNumberLanguageMap),
  ...Object.keys(internshipProjectNumberLanguageMap),
]

const isSpeculative = (projectNumber: string) =>
  Object.keys(speculativesProjectNumberLanguageMap).includes(projectNumber)

const isInternship = (projectNumber: string) =>
  Object.keys(internshipProjectNumberLanguageMap).includes(projectNumber)

const isReentry = (projectNumber: string) =>
  Object.keys(reentryProjectNumberLanguageMap).includes(projectNumber)

function getSpeculativeOffer(offers: JobOfferType[]) {
  return offers.find((offer) => isSpeculative(offer.project_number))
}

function getInternshipOffer(offers: JobOfferType[]) {
  return offers.find((offer) => isInternship(offer.project_number))
}

function getReentryOffer(offers: JobOfferType[]) {
  return offers.find((offer) => isReentry(offer.project_number))
}

const JobOfferSection = ({ headerData, jobOffers }: any) => {
  const t = useTranslations('career')
  const { locale = 'de' } = useRouter()

  // Internship, unsolicited applications and offer to reentry are static and should be shown on top
  const { staticOffers, allOtherOffers } = jobOffers.reduce(
    (
      acc: { staticOffers: JobOfferType[]; allOtherOffers: JobOfferType[] },
      offer: JobOfferType,
    ) => {
      if (staticOfferProjectNames.includes(offer.project_number)) {
        const language = isSpeculative(offer.project_number)
          ? speculativesProjectNumberLanguageMap[
              offer.project_number as unknown as keyof typeof speculativesProjectNumberLanguageMap
            ]
          : isInternship(offer.project_number)
            ? internshipProjectNumberLanguageMap[
                offer.project_number as unknown as keyof typeof internshipProjectNumberLanguageMap
              ]
            : reentryProjectNumberLanguageMap[
                offer.project_number as unknown as keyof typeof reentryProjectNumberLanguageMap
              ]
        if (language !== locale) return acc

        return { ...acc, staticOffers: [...acc.staticOffers, offer] }
      } else {
        return { ...acc, allOtherOffers: [...acc.allOtherOffers, offer] }
      }
    },
    {
      staticOffers: [],
      allOtherOffers: [],
    },
  )

  const sortedOffers = [
    getSpeculativeOffer(staticOffers),
    getInternshipOffer(staticOffers),
    getReentryOffer(staticOffers),
  ].filter((offer) => offer != null) as JobOfferType[]

  return (
    <Card headerData={headerData}>
      {jobOffers.length === 0 ? (
        <div className="text-center">{t('noOffers')}</div>
      ) : (
        <>
          {sortedOffers.map((offer: JobOfferType) => (
            <JobOffer key={offer.jobDbId} data={offer} color="dark-blue" />
          ))}
          {allOtherOffers.map((offer: JobOfferType) => (
            <JobOffer key={offer.jobDbId} data={offer} color="red" />
          ))}
        </>
      )}
    </Card>
  )
}

export default JobOfferSection
