import React, { useState, useEffect, useRef } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import AccordionItem from '@/components/accordionItem'
import withNewLines from '@/lib/withNewLines'
import { BlackButton } from '@/components/buttons'
import { getTypedLocale } from '../lib/typeHelper'
import Image from 'next/image'
import { cx } from '@monorepo/helper/cx'

export type JobOfferType = {
  jobDbId: number
  externalPostingName: string
  jobAdText: string
  job_owner_avatarurl: string
  job_owner_firstname: string
  job_owner_lastname: string
  job_owner_email: string
  applyOnlineLink: string
  locale: string
  project_number: string
}

type JobOfferProps = {
  data: JobOfferType
  color?: 'red' | 'dark-blue'
}

const APPLY_NOW_LABEL = {
  de: 'Jetzt bewerben',
  en: 'Apply now',
  fr: 'Postuler maintenant',
  it: 'Candidati ora',
} as const

const JobOffer = ({ data, color = 'red' }: JobOfferProps) => {
  const router = useRouter()
  const {
    query: { jobSlug: jobSlugParam },
  } = router
  const locale = getTypedLocale(router.locale)
  const t = useTranslations('career')
  const [expanded, setExpanded] = useState(
    jobSlugParam === data.jobDbId.toString(),
  )

  const jobRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (expanded) {
      jobRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      })
    }
  }, [expanded])

  useEffect(() => {
    if (jobSlugParam === data.jobDbId.toString()) {
      setExpanded(true)
    }
  }, [jobSlugParam, data.jobDbId])

  useEffect(() => {
    if (jobRef) {
      const ulElements = jobRef.current?.querySelector('ul')
      ulElements?.classList.add('ml-5', 'list-disc')
    }
  }, [expanded])

  const handleSetExpanded = () => {
    setExpanded(true)
    if (data.jobDbId) {
      router.replace(`/${locale}/jobs/${data.jobDbId}`, undefined, {
        shallow: true,
      })
    }
  }

  const handleSetCollapsed = () => {
    setExpanded(false)
    router.replace(`/${locale}/jobs`, undefined, { shallow: true })
  }

  return (
    <div ref={expanded ? jobRef : undefined}>
      <AccordionItem
        title={data.externalPostingName || ''}
        isExpanded={expanded}
        onExpand={handleSetExpanded}
        onCollapse={handleSetCollapsed}
        color={color}
      >
        <div className="p-4 text-left">
          {expanded && (
            <Head>
              <meta property="og:title" content={data.externalPostingName} />
            </Head>
          )}
          <h2
            id={data.jobDbId.toString()}
            className="py-8 text-xl leading-none text-red-500 sm:text-2xl"
          >
            {withNewLines(data.externalPostingName)}
          </h2>
          {data.jobAdText && (
            <div dangerouslySetInnerHTML={{ __html: data.jobAdText }} />
          )}

          {data.applyOnlineLink && (
            <BlackButton
              href={`${data.applyOnlineLink}`}
              className="my-4"
              target="_blank"
            >
              {
                APPLY_NOW_LABEL[
                  (data.locale as keyof typeof APPLY_NOW_LABEL) || locale
                ]
              }
            </BlackButton>
          )}

          <div className="my-4">
            <h2 className="text-red-500">{t('contact')}</h2>
            <ContactPerson
              avatarUrl={data.job_owner_avatarurl}
              firstName={data.job_owner_firstname}
              lastName={data.job_owner_lastname}
              email={data.job_owner_email}
            />
          </div>
        </div>
      </AccordionItem>
    </div>
  )
}

function ContactPerson({
  avatarUrl,
  firstName,
  lastName,
  email,
}: {
  avatarUrl?: string
  firstName: string
  lastName: string
  email: string
}) {
  return (
    <div className={cx('group my-4 flex h-full', avatarUrl && 'gap-4')}>
      <div className="relative">
        {avatarUrl ? (
          <Image
            sizes="100px"
            alt={`${firstName} ${lastName}`}
            src={avatarUrl}
            width={50}
            height={50}
            style={{ objectFit: 'cover' }}
            placeholder="empty"
            className="w-25 h-25 rounded-full border-4 border-yellow-500"
          />
        ) : null}
      </div>
      <div className="flex-col self-center font-medium">
        <div className="break-words pt-2 text-base font-medium leading-tight text-gray-700">
          {firstName} {lastName}
        </div>
        <div className="!text-base text-red-500">{email}</div>
      </div>
    </div>
  )
}

export default JobOffer
